<template>
  <div class="container">
    <MarketTable :tableHeight="800" />
  </div>
</template>

<script>
import { reactive, toRefs } from "vue"
import MarketTable from "@/components/MarketQuotations/index.vue"
export default {
  name: 'market',
  components: {
    MarketTable
  },
  setup() {
    const data = reactive({

    })
    return {
      data
    }
  }
}
</script>

<style lang="less" scoped>
</style>
